import "isomorphic-fetch"
import { getUser } from "./auth"

const SERVER = process.env.GATSBY_API_URL


// Detect if the app is running in a browser.
export const isBrowser = () => typeof window !== "undefined"

// Get ipfs hash
export const getHash = async (fileName) => {
  try {
    // Get the JWT token for the currently logged-in user.
    const token = getUser().jwt ? getUser().jwt : ""

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        fileName,
      }),
    }
    const resp = await fetch(`${SERVER}/hash/`, options)

    if (resp.ok) {
      return resp.json()
    } else {
      return false
    }
  } catch (err) {
    // If something goes wrong , return false.
    return false
  }
}

// Get ipfs hash
export const getSha256proof = async (payloadId) => {
  try {
    // Get the JWT token for the currently logged-in user.
    const token = getUser().jwt ? getUser().jwt : ""

    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }
    }
    const resp = await fetch(`${SERVER}/hash/sha256/${payloadId}`, options)

    if (resp.ok) {
      return resp.json()
    } else {
      return false
    }
  } catch (err) {
    // If something goes wrong , return false.
    return false
  }
}

