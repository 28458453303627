import React from "react"
import { navigate } from "gatsby"
import { isLoggedIn } from "../services/auth"
import Layout from "../components/layout"

let PrototypeUI =
  typeof window !== `undefined`
    ? require("../components/prototypeUI").default
    : null

let _this
class Prototype extends React.Component {
  constructor(props) {
    super(props)
    _this = this
    this.state = {
      render: isLoggedIn(),
    }
  }

  render() {
    return (
      <Layout hideFooter={true}>{_this.state.render && PrototypeUI && <PrototypeUI />}</Layout>
    )
  }

  // React Lifecycle - component has mounted.
  async componentDidMount() {
    // If user is already logged in, forward them to the private area.
    if (typeof window !== `undefined`) {
      if (!isLoggedIn()) {
        navigate(`/login`)
      }
    }
  }
}

export default Prototype
