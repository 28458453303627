/* eslint-disable */
import React, { Component } from "react"
import PropTypes from "prop-types"
import { NotificationManager } from "react-notifications"
import { getHash } from "../../services/hash"
import "./prototype.css"
// Uppy imports
const Uppy = require("@uppy/core")
const Tus = require("@uppy/tus")

const { Dashboard } = require("@uppy/react")

import "@uppy/core/dist/style.css"
import "@uppy/dashboard/dist/style.css"


import { Content, Button, Inputs, Box, Row, Col } from "adminlte-2-react"

const SERVER = process.env.GATSBY_API_URL

//instantiate uppy
const uppy = Uppy({
  meta: { test: "avatar" },
  allowMultipleUploads: true,
  debug: false,
  restrictions: {
    maxFileSize: null,
    maxNumberOfFiles: 1,
    minNumberOfFiles: 1,
    allowedFileTypes: null, //type of files allowed to load
  },
  onBeforeUpload: files => {
    const updatedFiles = Object.assign({}, files)
    Object.keys(updatedFiles).forEach(fileId => {
      const indexName = fileId.lastIndexOf("/")
      const fileName = fileId.substring(indexName, fileId.length)
      uppy.setFileMeta(fileId, { fileNameToEncrypt: fileName })
    })
    return updatedFiles
  },
  onBeforeFileAdded: (currentFile, files) => {
    _this.setState({
      ipfsHash: "",
    })
  },
})
// Adding plugins
uppy.use(Tus, { endpoint: `${SERVER}/files` })

uppy.on("complete", result => {
  //
})

let _this

class PrototypeUI extends React.Component {
  constructor(props) {
    super(props)
    _this = this
    this.state = {
      loaded: true,
      ipfsHash: "",
    }
  }

  render() {
    return (
      <>
        <div className="prototype-container">
          <Row>
            <Col xs={12} className="prototype-content">
              <Dashboard uppy={uppy} plugins={["Tus"]} {..._this.props} />
            </Col>
            <Col xs={12} className="prototype-content">
              {_this.state.ipfsHash && (
                <p>
                  IPFS Hash: <b>{_this.state.ipfsHash}</b>
                </p>
              )}
            </Col>
            <Col xs={12} className="prototype-content">
              <Button type="primary" text="Submit" onClick={this.submitData} />
            </Col>
          </Row>
        </div>
      </>
    )
  }



  componentWillUnmount() {
    this.uppy && this.uppy.close()
  }

  async submitData() {
    let fileName
    try {
      // Get file name from uppy state.
      const uppyState = uppy.getState()

      Object.keys(uppyState.files).forEach(fileId => {
        // Add the uploaded file name to the array.
        fileName = uppyState.files[fileId].name

        // console.log(`fileName: ${fileName}`)
      })

      //uppy error handler
      await _this.uppyHandler()
      const result = await getHash(fileName)
      _this.setState({
        ipfsHash: result.hash,
      })
      _this.resetUppy()

      NotificationManager.success("Success!", "Success", 900)
    } catch (error) {
      NotificationManager.error(error.message, "Error",2000)
    }
  }

  async uppyHandler() {
    return new Promise((resolve, reject) => {
      try {
        // Start to upload files via uppy
        uppy
          .upload()
          .then(async result => {
            // console.info("Successful uploads:", result.successful)
            try {
              // Upload failed due to no file being selected.
              if (result.successful.length <= 0 && result.failed.length <= 0) {
                return reject(new Error("File is required"))
                //throw new Error('File is required')
              } else if (result.failed.length > 0) {
                // Upload failed (for some other reason)

                // Error updload some file
                return reject(new Error("Fail to upload Some Files"))

                //throw new Error('Fail to upload Some Files')
              }
            } catch (error) {
              throw error
            }
            resolve(true)
          })
          .catch(error => {
            return reject(new Error("File is required"))
          })
      } catch (error) {
        return reject(error)
      }
    })
  }
  // Reset uppy state
  resetUppy() {
    uppy && uppy.reset()
  }
}
export default PrototypeUI
